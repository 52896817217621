import React from 'react';
// export const DeployTarget = "production";
export const DeployTarget = "staging";
export const Spacer = ({size}) => (<div style={{height:size+'rem'}}></div>);
export const userRoles = ["Admin","Manager","Team Lead","User"];

//icons
export const AddIcon = ({ fill }) => (<svg fill={ fill } viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M11.5 3a8.5 8.5 0 108.5 8.5A8.51 8.51 0 0011.5 3zm0 16a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5z"/><path d="M12 7h-1v4H7v1h4v4h1v-4h4v-1h-4V7z"/></svg>);
export const ArrowDoubleDownIcon = ({ fill }) => (<svg fill={ fill } viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M20 10.75l-8 6-8-6v1.26L12 18l8-5.99v-1.26z"/><path d="M20 6.75l-8 6-8-6v1.26L12 14l8-5.99V6.75z"/></svg>);
export const ArrowDoubleRightIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M16.75 12l-6 8h1.26L18 12l-5.99-8h-1.26l6 8z"/><path d="M12.75 12l-6 8h1.26L14 12 8.01 4H6.75l6 8z"/></svg>);
export const ArrowHeadRightIcon = ({ fill }) => (<svg fill={ fill } alt="right icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M14.75 12l-6 8h1.26L16 12l-5.99-8H8.75l6 8z"/></svg>);
export const UserIcon = ({ fill }) => (<svg fill={ fill } viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M12 14c3 0 4-1.84 4-4.12V7.12C16 4.84 15 3 12 3S8 4.84 8 7.12v2.76C8 12.16 9 14 12 14zM9 7.12C9 4.32 10.71 4 12 4s3 .32 3 3.12v2.76c0 2.8-1.71 3.12-3 3.12s-3-.32-3-3.12z"/><path d="M21 18.45A5.48 5.48 0 0017.49 13a4.54 4.54 0 01-.42.89A4.51 4.51 0 0120 18.45V20H4v-1.55a4.5 4.5 0 012.93-4.55 5.33 5.33 0 01-.43-.9A5.47 5.47 0 003 18.45V21h18v-2.55z"/></svg>);
export const GearIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 7a3 3 0 113-3 3 3 0 01-3 3z"/><path d="M18.84 13a5.81 5.81 0 00.08-1 6.19 6.19 0 00-.08-1.07l1.78-1.5a7.24 7.24 0 00-.53-1.36 9.09 9.09 0 00-.65-1.14 8.56 8.56 0 00-.93-1.14l-2.2.8a6.71 6.71 0 00-1.8-1l-.41-2.3A8.63 8.63 0 0012.66 3h-1.32a8.63 8.63 0 00-1.44.22l-.41 2.3a6.71 6.71 0 00-1.8 1l-2.2-.8a8.56 8.56 0 00-.93 1.14A9.09 9.09 0 003.91 8a7.24 7.24 0 00-.53 1.36l1.78 1.5A6.19 6.19 0 005.08 12a5.81 5.81 0 00.08 1l-1.79 1.5a8.62 8.62 0 00.52 1.37A9.09 9.09 0 004.54 17a11.18 11.18 0 00.92 1.14l2.19-.79a6.74 6.74 0 001.84 1.06l.41 2.3a7.81 7.81 0 001.45.22h1.3a7.81 7.81 0 001.45-.22l.41-2.3a6.74 6.74 0 001.84-1.06l2.19.79a11.18 11.18 0 00.92-1.14 9.09 9.09 0 00.65-1.14 8.62 8.62 0 00.52-1.37zm.36 2.45a6.64 6.64 0 01-.57 1c-.12.19-.26.36-.39.53l-1.55-.55-.53-.2-.44.36a5.9 5.9 0 01-1.57.91l-.53.2-.1.56-.29 1.63-.68.07L12 20h-.59c-.22 0-.43 0-.64-.06l-.29-1.63-.1-.56-.53-.2a5.9 5.9 0 01-1.57-.91l-.44-.36-.53.2-1.55.52-.39-.52a7.41 7.41 0 01-.58-1c-.09-.19-.18-.39-.26-.6L5.8 13.8l.44-.37-.09-.56a4.94 4.94 0 01-.07-.87 5.62 5.62 0 01.07-.92l.09-.55-.44-.37L4.54 9.1c.08-.2.17-.39.26-.59a9.31 9.31 0 01.58-1L5.79 7l1.56.57.52.19.44-.35a5.62 5.62 0 011.53-.88l.53-.2.1-.56.29-1.63c.22 0 .45-.06.68-.08L12 4h.59c.21 0 .43 0 .65.07l.29 1.63.1.56.53.2a5.62 5.62 0 011.53.88l.44.35.52-.19 1.56-.5c.14.17.28.35.4.53a8.84 8.84 0 01.59 1c.09.2.18.39.26.59l-1.26 1.04-.44.37.09.55a5.62 5.62 0 01.07.92 4.94 4.94 0 01-.07.87l-.09.56.44.37 1.27 1.06c-.08.21-.17.42-.27.62z"/></svg>);
export const LoginIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"/></g></svg>);
export const MenuIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M3 6h18v1H3zM3 16h18v1H3zM3 11h18v1H3z"/></svg>);
export const EditIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M16.49 3.52A3.24 3.24 0 0014.85 3a2.61 2.61 0 00-2.21 1.5L6 16v4.28a.72.72 0 00.34.62.7.7 0 00.36.1.73.73 0 00.35-.09l3.73-2.2 6.7-11.35a2.5 2.5 0 00-.99-3.84zM10.05 18L7 19.78v-3.51l5.28-9.15 3.15 1.74zm6.57-11.15L15.94 8l-3.16-1.74L13.5 5c.6-1 1.17-1 1.35-1a2.35 2.35 0 011.15.38 2 2 0 011 1.07 1.93 1.93 0 01-.38 1.4z"/></svg>);
export const DeleteIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M17 20H7V8H6v13h12V8h-1v12zM6 5h12v1H6z"/><path d="M10 9h1v9h-1zM10 3h4v1h-4zM13 9h1v9h-1z"/></svg>);
export const UserGroupIcon = ({ fill }) => (<svg fill={ fill }  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M10 16a2.69 2.69 0 0 0 3-3v-2a2.69 2.69 0 0 0-3-3 2.69 2.69 0 0 0-3 3v2a2.69 2.69 0 0 0 3 3zm-2-5c0-1.4.6-2 2-2s2 .6 2 2v2c0 1.4-.6 2-2 2s-2-.6-2-2zM16.5 10c1.86 0 2.5-1 2.5-2.25v-1.5C19 5 18.37 4 16.5 4S14 5 14 6.25v1.5C14 9 14.63 10 16.5 10zM15 6.25C15 5.5 15.26 5 16.5 5s1.5.5 1.5 1.25v1.5C18 8.5 17.74 9 16.5 9S15 8.5 15 7.75z"/><path d="M14 14a4.78 4.78 0 0 1-.22 1A4 4 0 0 1 16 18.87V19H4v-.13A3.89 3.89 0 0 1 6.19 15 4.54 4.54 0 0 1 6 14a4.9 4.9 0 0 0-3 4.87V20h14v-1.13A5.07 5.07 0 0 0 14 14zM21 12.25a3.32 3.32 0 0 0-1.61-3.09 2.56 2.56 0 0 1-.5.86A2.16 2.16 0 0 1 20 12h-5v1h6v-.75z"/></svg>);
export const EmailIcon = ({ fill }) => (<svg fill={ fill }  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M3 6v12h18V6zm16.43 1L12 11.75 4.57 7zM20 17H4V7.9l8 5.1 8-5.1z"/></svg>);
export const TabletIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M5 3v18h14V3zm13 17H6v-3h12zm0-4H6V4h12z"/><path d="M11.56 19h.88a.5.5 0 1 0 0-1h-.88a.5.5 0 1 0 0 1z"/></svg>);
export const QuestionIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M12 3a9 9 0 109 9 9 9 0 00-9-9zm0 17a8 8 0 118-8 8 8 0 01-8 8z"/><path d="M11.72 6H10v1.5h1.67c1.42 0 2.83.65 2.83 1.89 0 1.84-3.2 2.68-3.2 5V15h1.5v-.44c0-1.87 3.2-2 3.2-5.17C16 7.4 14.68 6 11.72 6zM13 16h-2v2h2v-2z"/></svg>);
export const CarIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M19.34 9.22l.32-.31.82.16h.09a.43.43 0 00.08-.86L19.59 8a.41.41 0 00-.42.16l-.8.8h.05H18c-.22-1.83-1.63-3-3.75-3H9.8C7.68 6 6.27 7.17 6.05 9h-.47l-.8-.8a.41.41 0 00-.37-.2l-1.06.21a.43.43 0 00.08.86h.09l.82-.16.32.31C3.56 9.7 3 11 3 13v3.23a.78.78 0 00.78.77h1.83C6.15 17 7 17 7 16h10c0 1 .85 1 1.39 1h1.83a.78.78 0 00.78-.77V13c0-2-.56-3.3-1.66-3.78zM9.8 7h4.4c1.56 0 2.55.74 2.76 2H7c.25-1.26 1.24-2 2.8-2zM20 16h-1.61a2.87 2.87 0 01-.39 0v-.5a.5.5 0 00-.5-.5H16a1 1 0 00-1-1H9a1 1 0 00-1 1H6.5a.5.5 0 00-.5.5v.5a2.87 2.87 0 01-.39 0H4v-3c0-2.64.91-3 1.79-3h12.42c.88 0 1.79.36 1.79 3z"/><path d="M18.56 11h-1.13A1.43 1.43 0 0016 12.43a.07.07 0 00.07.07h2.73a.2.2 0 00.2-.2v-.64c0-.56-.08-.66-.44-.66zM6.57 11H5.44c-.36 0-.43.1-.43.66v.64a.2.2 0 00.2.2h2.72a.07.07 0 00.07-.07A1.43 1.43 0 006.57 11z"/></svg>);
export const KeyIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M18.07 7a7.9 7.9 0 0 0-2.71.71c-1.47.64-2.18 1.39-2.18 2.29H5l-.29.29-1 1L3 12l.69.71 1 1 .69.7.69-.7.67-.71h.17l.69.71.69.7.69-.7.69-.71h.17l.69.71.69.7.7-.7.69-.71h.57c0 .9.71 1.65 2.18 2.29a7.9 7.9 0 0 0 2.71.71C19 16 21 16 21 14V9c0-2-2-2-2.93-2zm-4.89 5h-1l-1 1-1-1h-1l-1 1-1-1h-1l-1 1-1-1 1-1H13zM20 14c0 1-1 1-1.95 1s-3.91-1-3.91-2v-3c0-1 2.93-2 3.91-2S20 8 20 9z"/><path d="M18.56 9a.49.49 0 0 0-.49.5v4a.5.5 0 0 0 .49.5.49.49 0 0 0 .48-.5v-4a.48.48 0 0 0-.48-.5z"/></svg>);
export const GlobeIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M12 3a9 9 0 109 9 9 9 0 00-9-9zm7.41 6H15.8a14.34 14.34 0 00-1.34-4.61A8 8 0 0119.41 9zM15 12c0 .7 0 1.37-.09 2H9.09C9 13.37 9 12.7 9 12s0-1.37.09-2h5.82c.09.63.09 1.3.09 2zm-3 8a8 8 0 01-1.11-.09A11.14 11.14 0 019.21 15h5.58a11.14 11.14 0 01-1.68 4.91A8 8 0 0112 20zM9.21 9a11.14 11.14 0 011.68-4.91A8 8 0 0112 4a8 8 0 011.11.09A11.14 11.14 0 0114.79 9zm.34-4.61A14.08 14.08 0 008.2 9H4.59a8 8 0 014.96-4.61zM4.26 10H8.1c-.1.65-.1 1.31-.1 2s0 1.35.1 2H4.26a7.82 7.82 0 010-4zm.33 5H8.2a14.08 14.08 0 001.35 4.61A8 8 0 014.59 15zm9.87 4.61A14.34 14.34 0 0015.8 15h3.61a8 8 0 01-4.95 4.61zM19.74 14H15.9c.06-.65.1-1.31.1-2s0-1.35-.1-2h3.84a7.82 7.82 0 010 4z"/></svg>);
export const StackIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M21 8l-9-5-9 5 9 5zm-9-3.86L18.94 8 12 11.8 5.06 8z"/><path d="M12.01 15.8H12L5.06 12l-1.04-.57L3 12l9 5 8.99-5-1.01-.56-7.97 4.36z"/><path d="M12.01 19.8H12L5.06 16l-1.04-.57L3 16l9 5 8.99-5-1.01-.56-7.97 4.36z"/></svg>);
export const FlagIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M18.1 9.5A4.4 4.4 0 0115.74 7a6.81 6.81 0 00-3.87-3.49A6.57 6.57 0 009.43 3 3 3 0 009 3L5.45 13.18 3 19.7l.83.3 2.69-7.06a8.39 8.39 0 011.85.5A4.63 4.63 0 0111 16a6.09 6.09 0 003.27 3.4A12 12 0 0017 20l4-10a14.54 14.54 0 01-2.9-.5zm-1.74 9.42a8.6 8.6 0 01-1.73-.41 5.07 5.07 0 01-2.73-2.86 5.65 5.65 0 00-3.12-3.12A9.41 9.41 0 006.92 12l2.79-8a5.76 5.76 0 011.78.47 5.74 5.74 0 013.33 3 5.36 5.36 0 002.89 3 8.78 8.78 0 001.87.43z"/></svg>);
export const ChartIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M20 20h-5v-7h-1v7H9v-4H8v4H4v-9H3v10h18V11h-1v9z"/><path d="M16 3v1h3.36L9 12 4.89 7H3v1h1.47l4.46 5.35L20 4.77V8h1V3h-5z"/></svg>);
export const MapIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M11.05 13.73L12 15l1-1.27s.74-1 1.47-2.15C15.84 9.31 16 8.34 16 7.81A3.91 3.91 0 0 0 12 4a3.91 3.91 0 0 0-4 3.81c0 .53.16 1.5 1.58 3.77.73 1.16 1.42 2.11 1.47 2.15zM12 5a2.92 2.92 0 0 1 3 2.81 7.78 7.78 0 0 1-1.43 3.24c-.68 1.09-1.35 2-1.42 2.09l-.15.19-.15-.2s-.7-.94-1.42-2.08A7.78 7.78 0 0 1 9 7.81 2.92 2.92 0 0 1 12 5z"/><path d="M18 12h-2.24l-.55 1h2.22l1.8 3H4.77l1.8-3h2.22l-.55-1H6l-3 5h18l-3-5z"/></svg>);
export const InfoIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M11 7h2v2h-2zM11 11h2v6h-2z"/><path d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 17a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/></svg>);
export const PinIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><path d="M12 5a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 7a3 3 0 1 1 3-3 3 3 0 0 1-3 3z"/><path d="M12 2C8.14 2 5 4.48 5 8.49c0 1.69.29 2.65 2.77 7 1.27 2.21 2.52 4 2.57 4.11L12 22l1.66-2.42c.05-.07 1.3-1.9 2.57-4.11 2.48-4.33 2.77-5.31 2.77-7C19 4.48 15.86 2 12 2zm3.36 13c-1.21 2.12-2.4 3.86-2.53 4L12 20.23 11.16 19s-1.25-1.8-2.52-4C6.13 10.59 6 9.89 6 8.49 6 5.26 8.47 3 12 3s6 2.26 6 5.49c0 1.42-.2 2.24-2.64 6.51z"/></svg>);
export const CheckIcon = ({ fill }) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M9 19l-6-7h1.5l4.49 5.36L19.5 5H21L9 19z"/></svg>);
export const DownloadIcon = ({fill}) => (<svg fill={ fill } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%"><path d="M15 9v1h4v8H4v-8h4V9H3v10h17V9h-5z"/><path d="M11.5 16l4-4H14l-2 2V3h-1v11l-2-2H7.5l4 4z"/></svg>);
export const DLAbbrDesMap = {
    'DCA': 'Jurisdiction-specific vehicle class',
    'DBA': 'Expiry Date',
    'DCS': 'Last Name',
    'DAC': 'First Name',
    'DBD': 'Issue Date',
    'DBB': 'Birth Date',
    'DBC': 'Gender',
    'DAY': 'Eye Color',
    'DAU': 'Height',
    'DAG': 'Street',
    'DAI': 'City',
    'DAJ': 'State',
    'DAK': 'Zip',
    'DAQ': 'License Number',
    'DCF': 'Document Discriminator',
    'DCG': 'Issue Country',
    'DAH': 'Street 2',
    'DAZ': 'Hair Color',
    'DCI': 'Place of birth',
    'DCJ': 'Audit information',
    'DCK': 'Inventory Control Number',
    'DBN': 'Alias / AKA Family Name',
    'DBG': 'Alias / AKA Given Name',
    'DBS': 'Alias / AKA Suffix Name',
    'DCU': 'Name Suffix',
    'DCE': 'Physical Description Weight Range',
    'DCL': 'Race / Ethnicity',
    'DCM': 'Standard vehicle classification',
    'DCN': 'Standard endorsement code',
    'DCO': 'Standard restriction code',
    'DCP': 'Jurisdiction-specific vehicle classification description',
    'DCQ': 'Jurisdiction-specific endorsement code description',
    'DCR': 'Jurisdiction-specific restriction code description',
    'DDA': 'Compliance Type',
    'DDB': 'Card Revision Date',
    'DDC': 'HazMat Endorsement Expiration Date',
    'DDD': 'Limited Duration Document Indicator',
    'DAW': 'Weight(pounds)',
    'DAX': 'Weight(kilograms)',
    'DDH': 'Under 18 Until',
    'DDI': 'Under 19 Until',
    'DDJ': 'Under 21 Until',
    'DDK': 'Organ Donor Indicator',
    'DDL': 'Veteran Indicator',
    // old standard
    'DAA': 'Customer Full Name',
    'DAB': 'Customer Last Name',
    'DAE': 'Name Suffix',
    'DAF': 'Name Prefix',
    'DAL': 'Residence Street Address1',
    'DAM': 'Residence Street Address2',
    'DAN': 'Residence City',
    'DAO': 'Residence Jurisdiction Code',
    'DAR': 'License Classification Code',
    'DAS': 'License Restriction Code',
    'DAT': 'License Endorsements Code',
    'DAV': 'Height in CM',
    'DBE': 'Issue Timestamp',
    'DBF': 'Number of Duplicates',
    'DBH': 'Organ Donor',
    'DBI': 'Non-Resident Indicator',
    'DBJ': 'Unique Customer Identifier',
    'DBK': 'Social Security Number',
    'DBM': 'Social Security Number',
    'DCH': 'Federal Commercial Vehicle Codes',
    'DBR': 'Name Suffix',
    'PAA': 'Permit Classification Code',
    'PAB': 'Permit Expiration Date',
    'PAC': 'Permit Identifier',
    'PAD': 'Permit IssueDate',
    'PAE': 'Permit Restriction Code',
    'PAF': 'Permit Endorsement Code',
    'ZVA': 'Court Restriction Code',
    'DAD': 'Middle Name'
};

export const StatesList = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]