import React, { useState, Fragment, useEffect,useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import Help from "./Help";
import Permissions from "./Permissions";
import Events from "./Events/Events";
import Event from "./Events/Event";
import EventName from "./Events/EventName";
// import packageJson from '../../../package.json';
import Registration from "./Events/Registration";
import Registrations from "./Events/Registrations";
import Reports from "./Events/Reports";
import Users from "./Configuration/Users";
import Settings from "./Configuration/Settings";
import GlobalReports from "./Configuration/GlobalReports";
import Dealers from "./Configuration/Dealers";
import DealerLocator from "./Configuration/DealerLocator";
import Models from "./Configuration/Models";
import Groups from "./Configuration/Groups";
import Oops from "../main/Oops";
import Invite from "./Configuration/Invite";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import Profile from "./Profile";
import {
  UserIcon,
  GearIcon,
  ArrowDoubleRightIcon,
  ArrowDoubleDownIcon,
  LoginIcon,
  MenuIcon,
  UserGroupIcon,
  QuestionIcon,
  CarIcon,
  KeyIcon,
  GlobeIcon,
  StackIcon,
  FlagIcon,
  ChartIcon,
  MapIcon,
  DeployTarget
} from "../../Constants";
import { EventDataContext,EventDataProvider } from "./EventDataContext"

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import PasswordForgetPage from './components/ForgotPassword';
// import AccountPage from './components/Account';
// import ManagePage from './components/Manage';
import "../../assets/css/dashboard.css";

const DashboardLayout = (props) => {
  const { user } = useContext(firebaseAuth);
  const [showTitles, setShowTitles] = useState("sidebar-titles-hide");
  const [showSidebar, setShowSidebar] = useState("sidebar-hide");
  const [sidebarOpen, setSidebarOpen] = useState(false);


  // const caching= ()=> {
  //   console.log(packageJson.version);
  //   console.log('version');
  // let version = localStorage.getItem('version');
  //     if(version!=packageJson.version)
  //     {
  //         if('caches' in window){
  //          caches.keys().then((names) => {
  //         // Delete all the cache files
  //         names.forEach(name => {
  //             caches.delete(name);
  //         })
  //     });
  
  //     // Makes sure the page reloads. Changes are only visible after you refresh.
  //     window.location.reload(true);
  // }
  
  //       localStorage.clear();
  //       localStorage.setItem('version',packageJson.version);
  //     }
  // };

  useEffect(() => {
  document.getElementById('loading-container').classList.add('hide-loading-container');
  // caching();

  }, []);
  function toggleSidebar() {
    if (showSidebar === "sidebar-hide") {
      setShowSidebar("");
      setShowTitles("sidebar-titles-show");
    } else {
      setShowSidebar("sidebar-hide");
      setShowTitles("sidebar-titles-hide");
    }
  }

  function toggleSidebarTitles() {
    if (showTitles === "sidebar-titles-show") {
      setShowTitles("sidebar-titles-hide");
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
      setShowTitles("sidebar-titles-show");
    }
  }
  function AuthenticatedSidebarLinks(props) {
    return (
      <Fragment>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link" to="/manage">
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/manage/event">
              <i className="md">
                <FlagIcon />
              </i>
              <span className={showTitles + " px-2"}>Event</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/manage/registrations">
              <i className="md">
                <StackIcon />
              </i>
              <span className={showTitles + " px-2"}>Registrations</span>
            </Link>
          </li>

        </ul>
        <h4
          className={
            showTitles +
            " d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          }
        >
          <span className={showTitles + " px-2"}>Configuration</span>
        </h4>
        <ul className="nav flex-column mb-2">
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/settings">
              {" "}
              <i className="md">
                <GearIcon />
              </i>
              <span className={showTitles + " px-2"}>Settings</span>
            </Link>
          </li>
          {/* <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/global-reports">
              {" "}
              <i className="md">
                <GlobeIcon />
              </i>
              <span className={showTitles + " px-2"}>Summary Reports</span>
            </Link>
          </li> */}
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/dealers">
              {" "}
              <i className="md">
                <KeyIcon />
              </i>
              <span className={showTitles + " px-2"}>Dealers</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/dealer-locator">
              {" "}
              <i className="md">
                <MapIcon />
              </i>
              <span className={showTitles + " px-2"}>Dealer Locator</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/models">
              {" "}
              <i className="md">
                <CarIcon />
              </i>
              <span className={showTitles + " px-2"}>Models</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/groups">
              {" "}
              <i className="md">
                <UserGroupIcon />
              </i>
              <span className={showTitles + " px-2"}>Groups</span>
            </Link>
          </li>
          <li className={user?.role === 1 ? "nav-item" : "d-none"}>
            <Link className="nav-link" to="/manage/users">
              {" "}
              <i className="md">
                <UserIcon />
              </i>
              <span className={showTitles + " px-2"}>Users</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" to="/manage/help">
              {" "}
              <i className="md">
                <QuestionIcon />
              </i>
              <span className={showTitles + " px-2"}>Help</span>
            </Link>
          </li> */}
        </ul>
      </Fragment>
    );
  }
  function NonAuthenticatedSidebarLinks(props) {
    return (
      <Fragment>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link" to="/manage/sign-in">
              <i className="md">
                <LoginIcon />
              </i>
              <span className={showTitles + " px-2"}>Login</span>
            </Link>
          </li>
        </ul>
      </Fragment>
    );
  }

  function ShowSidebarLinks(props) {
    if (user.isAuthenticated) {
      return <AuthenticatedSidebarLinks />;
    }
    return <NonAuthenticatedSidebarLinks />;
  }

  function AuthenticatedLinks(props) {
    return (
      <Fragment>
        <li className="nav-item">
          <Link className="nav-link" to="/manage/profile">
            <i className="md light">
              <UserIcon />
            </i>
          </Link>
        </li>
      </Fragment>
    );
  }
  function NonAuthenticatedLinks(props) {
    return (
      <li className="nav-item text-nowrap">
        <Link className="nav-link" to="/manage/sign-in">
          Login
        </Link>
      </li>
    );
  }

  function ShowLinks(props) {
    if (user.isAuthenticated) {
      return <AuthenticatedLinks />;
    }
    return <NonAuthenticatedLinks />;
  }
  // const eventData = {name:'test'};
  
  return (
    <EventDataProvider>

    <div id="dashboard-layout" >
      <nav
        id="nav"
        className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow"
      >
        <div className="d-md-none col-auto px-2" onClick={toggleSidebar}>
          <i className="sm light">
            <MenuIcon />
          </i>
        </div>
        <Link className="navbar-brand col-auto mr-0" to="/manage">
          pcna<span>events</span> <span className="fs-xxs">v{process.env.REACT_APP_VERSION}{DeployTarget === "staging" ? " (STAGING)" :""}</span>
        </Link>
        <div className="col navbar-brand"><EventName/></div>
        <ul className="navbar-nav px-3">
          <ShowLinks />
        </ul>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <nav
            className={"col-auto px-0 sidebar " + showSidebar}
            id="app-drawer"
          >
            <div
              className="d-xl-none d-md-block d-none pl-md-3 px-2 mt-2"
              onClick={toggleSidebarTitles}
            >
              <i className={sidebarOpen ? "d-none md" : "md"}>
                <ArrowDoubleRightIcon />
              </i>
              <i className={sidebarOpen ? "md" : "d-none md"}>
                <ArrowDoubleDownIcon />
              </i>
              <span className={showTitles + " px-2"}></span>
            </div>

            <div className="sidebar-sticky">
              <ShowSidebarLinks />
            </div>
          </nav>
          <main id="main" role="main" className="col ml-sm-auto pr-md-4">
          <DndProvider backend={HTML5Backend}>

            <Switch>
            <Route path="/manage/help" component={Help} />
            <Route path="/manage/permissions" component={Permissions} />

              <Route path="/manage/profile" component={Profile} />
              <Route path="/manage/sign-in" component={SignIn} />
              <Route path="/manage/reset-password" component={ResetPassword} />
              <Route path="/manage/forgot-password" component={ForgotPassword} />
              <Route path="/manage/users" component={Users} />
              <Route path="/manage/invite" component={Invite} />
              <Route path="/manage/groups" component={Groups} />
              <Route path="/manage/settings" component={Settings} />
              <Route exact path="/oops" component={Oops} />
              <Route path="/manage/dealers" component={Dealers} />
              <Route path="/manage/dealer-locator" component={DealerLocator} />
              <Route path="/manage/models" component={Models} />
              <Route path="/manage/global-reports" component={GlobalReports} />
              <Route path="/manage/event" component={Event} />
              <Route path="/manage/registrations" component={Registrations} />
              <Route path="/manage/registration/:id" component={Registration} />
              <Route path="/manage/registration" component={Registration} />
              <Route path="/manage/reports" component={Reports} />
              <Route path="/manage" component={Events} />
              {/*<Route path='/forgot-password' component={ForgotPassword} />
                                                        <Route path='/account' component={Account} />
                                                        <Route path='/manage' component={Manage} /> */}
            </Switch>
            </DndProvider>
          </main>
        </div>
      </div>
    </div>
    </EventDataProvider>
  );
};

export default DashboardLayout;
