import React,{useContext,useEffect} from "react";
import { Redirect } from 'react-router-dom'
import {firebaseAuth} from '../../providers/AuthProvider';
import {ArrowDoubleDownIcon} from '../../Constants';
import '../../assets/css/help.css';
import firebase from "firebase/app";


const Help = () => {
  
  const {user} = useContext(firebaseAuth);
  const callDaytona = async () => {
    console.log('hello');
    let daytona = await firebase.functions().httpsCallable("app/daytonaTickets");
    daytona().then(function (result) {
   
   console.log(result);
    });
  }
  useEffect(() => {
    // callDaytona();

  }, []);

  if(!user.isAuthenticated)
    return <Redirect to="/manage/sign-in" />

  return (
    <div className='container'>
      <h1>Help</h1>
        
      <p>
        User Guide coming soon!
      </p>
    </div>
  );
};

export default Help;
