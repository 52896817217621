import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
let envDetector = 'production';
if (window.location.hostname === 'localhost') {
    envDetector = "development"
} else if (window.location.hostname === 'pcnaevents.dev') {
    envDetector = "staging"
}
// Sentry.init({
//   dsn: "https://25b4942a0b2a4b4d83dc2b0174ff5a13@o1052628.ingest.sentry.io/6036423",
//   integrations: [new Integrations.BrowserTracing()],
//     environment:envDetector,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// denyUrls:['*cloudfront*']
// });

ReactDOM.render(
    
        <App />
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
