import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { firebaseAuth } from "../../providers/AuthProvider";
import { db } from "../../firebase";
import Alert from "react-bootstrap/Alert";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";

const ResetPassword = (props) => {
  const {
    inputs,
    setInputs,
    errors,
    setErrors,
    user,
    handleSignout
  } = useContext(firebaseAuth);
  const [redirect, setRedirect] = useState(false);
  let history = useHistory();
  let useQuery = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (user.isAuthenticated) 
      handleSignout();
  }, [user]);
  useEffect(() => {
    let new_account = useQuery.get('new_account');
    setInputs({ confirm_password: "", password: "", new_account:new_account ? true : false });
    setErrors([]);
  }, []);

  if (redirect) return <Redirect to="/manage" />;

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(inputs.password != inputs.confirm_password) {
      setErrors(["Passwords do not match."])
      return;
    }
    let code = useQuery.get('oobCode');
    firebase.auth().confirmPasswordReset(code, inputs.password)
    .then(function() {
      history.push('/manage/sign-in?pw_update=1');
    })
    .catch(function(error) {
      console.log(error);
      setErrors([error.message]);
    })
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputs((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <h1>{inputs.new_account ? "Create Password" : "Reset Password"}</h1>
        <div className="py-3"></div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="password">
                Password{" "}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="confirm_password">
                Confirm Password{" "}
              </label>
              <input
                type="password"
                id="confirm_password"
                name="confirm_password"
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Register
            </button>
          </div>
        </div>
        <div className="py-2"></div>
        <div className="row">
          <div className="col-auto">
          {errors[0] ? <Alert variant="danger">{errors[0]}</Alert> : ""}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
