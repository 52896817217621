import React,{useEffect} from "react";
import "../../assets/css/contact.css";
import { Helmet } from 'react-helmet';
// import * as Sentry from "@sentry/react";
const Oops = () => {
  // const [inputs, setInputs] = useState({});

  useEffect(() => {
    // Sentry.captureMessage("Navigated to oops");
    document.getElementById('loading-container').classList.remove('hide-loading-container');
    setTimeout(() => {    document.getElementById('loading-container').classList.add('hide-loading-container');
  },100);
  }, []);

  // const handleChange = (e) => {
  //   setInputs({
  //     ...inputs,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  return (
    <div className="">
              <Helmet>
        <title >eZentrum - Status</title>
        <meta name="description" content="The Home of Porsche Experiences - Explore past and upcoming experiences and discover opportunities to connect with the living legacy of our vehicles. On the road and off, we invite you to bring your passions to life across design, culture, travel, sport, and more." />
        <meta property="og:title" content="eZentrum - Accessibility" />
        <meta property="og:description" content="The Home of Porsche Experiences - Explore past and upcoming experiences and discover opportunities to connect with the living legacy of our vehicles. On the road and off, we invite you to bring your passions to life across design, culture, travel, sport, and more." />
        <meta property="og:image" content="https://res.cloudinary.com/dcx7bbpx8/image/upload/c_crop,h_627,w_1200/v1621365928/PCNA/ezentrum/eZentrum_IntroGraphic_576x432_shadowfortext-2.jpg" />
        </Helmet>
<div className="container small-container">
<div className="py-5"></div>
<p>There was a problem loading your page.  Please try again.</p>
      </div>
    </div>
  );
};

export default Oops;
