import React,{useContext} from "react";
import { Redirect } from 'react-router-dom'
import {firebaseAuth} from '../../providers/AuthProvider';
import {ArrowDoubleDownIcon} from '../../Constants';
import '../../assets/css/help.css';


const Help = () => {
  const {user} = useContext(firebaseAuth);
  if(!user.isAuthenticated)
    return <Redirect to="/manage/sign-in" />
  
  return (
    <div className='container'>
      <h1>User does not have permission to access this page.</h1>

    </div>
  );
};

export default Help;