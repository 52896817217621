import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardLayout from './components/manage/DashboardLayout';
import MainLayout from './components/main/MainLayout';
import AuthProvider from './providers/AuthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css';
import './assets/css/icons.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactLightbox from 'simple-react-lightbox'





const App = () => {
  // const { user } = useContext(firebaseAuth);
  return (
    <BrowserRouter>
        <AuthProvider>
          <SimpleReactLightbox>
        <Switch>
          <Route path={["/manage","/manage/*","/onsite","/onsite/*"]}>
            <DashboardLayout />

          </Route>
          <Route path={["/*", "/"]}>
            <MainLayout />

          </Route>
        </Switch>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      </SimpleReactLightbox>
        </AuthProvider>
    </BrowserRouter>
    
  );
};

export default App;
