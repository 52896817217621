import React,{useContext} from "react";
import { Redirect } from 'react-router-dom'
import {firebaseAuth} from '../../../providers/AuthProvider';
import {ArrowDoubleDownIcon} from '../../../Constants';


const GlobalReports = () => {
  const {user} = useContext(firebaseAuth);
  if(!user.isAuthenticated)
    return <Redirect to="/manage/sign-in" />
  
  return (
    
    <div className='container'>
      <h1>Global Reports</h1>
      <p>
        Systemm global report dashboard coming soon.
      </p>
    </div>
  );
};

export default GlobalReports;
