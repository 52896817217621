// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import { DeployTarget } from "./Constants";

// Add the Firebase products that you want to use
import 'firebase/firestore';
import "firebase/storage";
import 'firebase/auth';
import 'firebase/functions';

const prodConfig = {
  apiKey: "AIzaSyA-HMkvI0sAAzGJeJ8kWz2R6p6Xz31svTo",
  authDomain: "pcnaevents.firebaseapp.com",
  databaseURL: "https://pcnaevents.firebaseio.com",
  projectId: "pcnaevents",
  storageBucket: "pcnaevents.appspot.com",
  messagingSenderId: "629879893091",
  appId: "1:629879893091:web:e28ebdc72c81839c235c56",
  measurementId: "G-SFXVJQ75TV"
};
 
const devConfig = {
  apiKey: "AIzaSyB1us97LPDr5KRqw4YFV1E_o8U5t2HgHqw",
  authDomain: "pcnaevents-stg.firebaseapp.com",
  databaseURL: "https://pcnaevents-stg-default-rtdb.firebaseio.com",
  projectId: "pcnaevents-stg",
  storageBucket: "pcnaevents-stg.appspot.com",
  messagingSenderId: "71185168214",
  appId: "1:71185168214:web:d8eb65698a3d408ffe08f7",
  measurementId: "G-ZGW62P6LQ5"
};
const config =
  DeployTarget === 'production' ? prodConfig : devConfig;

// Initialize Firebase
firebase.initializeApp(config);
// firebase.analytics();
firebase.firestore.setLogLevel('error');
const db = firebase.firestore();
// db.settings({experimentalAutoDetectLongPolling:true});
const storage = firebase.storage();

export {db,storage, firebase as default};
console.log('persistence, no auto long poll');

// db.enablePersistence({synchronizeTabs: true}).catch(err => {
//   if (err.code === 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     console.log('persistance failed');
//   } else if (err.code === 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     console.log('persistance not available');
//   }
// });
if (window.location.hostname === 'localhost') {
  console.log("testing locally -- hitting local functions and firestore emulators");
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
  // firebase.firestore().settings({
  //   host: 'localhost:8080',
  //   ssl: false
  // });
} 