import React, { useState, useEffect, useContext, useReducer } from "react";
import { Redirect } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon } from "../../../Constants";
import "../../../assets/css/groups.css";

const Dealers = () => {
  const { user } = useContext(firebaseAuth);
  const reducer = (state, payload) => ({ ...state, ...payload });

  const [data, setData] = useReducer(reducer, { items: [],
    isFetching: false,
    message: null, });

  const [inputs, setInputs] = useState(null);

  const fetch = async (items) => {
    setData({
      items: data.items,
      isFetching: true,
      message: null,
    });
    let tmpItems2 = [];
    db.collection("dealers")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          tmpItems2.push({id:doc.id,data: doc.data()});
        });
        setData({
          items: tmpItems2,
          isFetching: false,
          message: null,
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        setData({
          items: data.items,
          isFetching: false,
          message: null,
        });
      });
  };
  useEffect(() => {
    setData({
      isFetching: true,
      message: null,
    });
    let tmpItems = [];
    db.collection("dealers")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          tmpItems.push({id:doc.id,data: doc.data()});
        });
        setData({
          items: tmpItems,
          isFetching: false,
          message: null,
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        setData({
          items: data.items,
          isFetching: false,
          message: null,
        });
      });
  }, []);
  if (!user.isAuthenticated) {
    return <Redirect to="/manage/sign-in" />;
  }
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading items.</div>;
    } else {
      return null;
    }
  }

  function Message(props) {
    if (data.message) {
      return <div className="alert alert-danger">{data.message}</div>;
    } else {
      return null;
    }
  }
  function toggleInputs(item) {
    setData({
      message: null,
    });
    if (!inputs) {
      setInputs(item);
    } else {
      setInputs(null);
    }
  }
  const deleteItem = () => {
    db.collection('dealers')
      .doc(inputs.id)
      .delete();
      setInputs(null);
      fetch();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(inputs.id) {
      db.collection("dealers").doc(inputs.id).set(inputs.data);
      setInputs(null);
      fetch();
    }
    else {
      db.collection("dealers").add(inputs.data)
      .then(function(docRef) {
        console.log(docRef);  
      db.collection("dealers").doc(docRef.id).set(inputs.data);
      setInputs(null);
      fetch();
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
  }
  };  
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      data: { ...inputs.data, [e.target.name]: e.target.value }
    });
  };
  
  return (
    <div className="container">
      <div className={!inputs ? "" : "d-none"}>
        <h1>Dealers</h1>
        <div className="py-2"></div>
        <div className="text-right">
          <button onClick={() => toggleInputs({id:null,data:null})} className="btn btn-theme">Add Dealer</button>
        </div>
        <div className="py-2"></div>
        <Fetching />
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th className="d-none d-md-table-cell">Code</th>
                <th className="d-none d-md-table-cell">Name</th>
                <th className="d-none d-md-table-cell">City</th>
                <th className="d-none d-md-table-cell">State</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.items.map((g, i) => (
                <tr
                  key={i}
                  className="editRow"
                  onClick={() => toggleInputs(g)}
                >
                  <td className="d-none d-md-table-cell">{g.data.code}</td>
                  <td className="d-none d-md-table-cell">{g.data.name}</td>
                  <td className="d-none d-md-table-cell">{g.data.city}</td>
                  <td className="d-none d-md-table-cell">{g.data.state}</td>
                  <td>
                    <i className="sm">
                      <EditIcon />
                    </i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={inputs ? "" : "d-none"}>
      <h1>Update Dealer</h1>
        <div className="py-2"></div>
        

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="code">Code</label>
              <input
                type="text"
                id="code"
                name="code"
                value={inputs?.data?.code || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={inputs?.data?.name || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="city">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={inputs?.data?.city || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="state">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={inputs?.data?.state || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="py-3"></div>
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-default"
              onClick={() => toggleInputs(null)}
            >
              Cancel
            </button>
          </div>
          <div className="col text-right">
            <button
              className="btn btn-link"
              onClick={() =>
                window.confirm("Are you sure to delete this record?")
                  ? deleteItem()
                  : console.log("Action cancelled by user.")
              }
            >
              <i className="sm">
                <DeleteIcon />
              </i>
            </button>
          </div>
        </div>
        <div className="py-3"></div>

        <Message />
      </div>
    </div>
  );
};


export default Dealers;
