import React, {useState, useEffect} from 'react';
import {authMethods} from './AuthMethods'
import firebase from '../firebase'

export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
  const initState = {email: '', password: ''}
  const [inputs, setInputs] = useState(initState)
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):{});


  const handleSignup = () => {
    authMethods.signup(inputs.email, inputs.password,setErrors ,setUser )
    console.log(errors, user);
  }
  const handleSignin = () => {
    authMethods.signin(inputs.email, inputs.password, setErrors, setUser)
  }
  const handleSignout = () => {
    authMethods.signout(setErrors, setUser)
  }
  useEffect(() => {
    setUser(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')):{}); 
    firebase.auth().onAuthStateChanged(userAuth => {
      if(userAuth) {
      userAuth.getIdTokenResult()
        .then((idTokenResult) => {
          if(idTokenResult) {
            let u = { isAuthenticated: true, email:idTokenResult.claims.email,user: idTokenResult.user, role: idTokenResult.claims.role, groups: idTokenResult.claims.groups,token: idTokenResult.token };
            localStorage.setItem('user',JSON.stringify(u));
            setUser(u);
          }
        });
      }
    });
  },[]);
  return (
    <firebaseAuth.Provider
    value={{
      //replaced test with handleSignup
      handleSignup,
      handleSignin,
      user,
      inputs,
      setInputs,
      errors,
      setErrors,
      handleSignout,
    }}>
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;