import React, {  useEffect, useContext, useState,useReducer,useCallback } from "react";
import { NavLink,Redirect } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon } from "../../../Constants";
import "../../../assets/css/groups.css";
import moment from "moment";
import { EventDataContext } from "../EventDataContext"
import { useDrag, useDrop } from 'react-dnd'
import {EventCard} from './EventCard';
// import * as Sentry from "@sentry/react";
const Events = () => {
  const { user } = useContext(firebaseAuth);
  const newEvent = { id:db.collection('events').doc().id,name:'New Event',email:'info',status:'Active',sortOrder:0,
    dataFields:{dealer: {visible: true, required: false}, experience:  {visible: true, required: false}, purchaseTimeFrame:  {visible: true, required: false}, firstName:  {visible: true, required: true}, title:  {visible: false, required: false},
    address1: {visible: true, required: true},city: {required: true, visible: true}, dealer: {visible: true},email: {visible: true, required: true},experience: {visible: true},firstName: {visible: true, required: true},lastName: {visible: true, required: true},
    modelInterest: {visible: true},phone: {visible: false},postal: {required: true, visible: true},purchaseTimeFrame: {visible: true},state: {required: true, visible: true},title: {visible: true, required: true}
  }
}
  
  const reducer = (state, payload) => ({ ...state, ...payload });
  const { eventData, setEventData } = useContext(EventDataContext);
const [searchText,setSearchText] = useState("");
  const [data, setData] = useReducer(reducer, {
    items: [],
    isFetching: false,
    message: null,
  });
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    let tmpItems = data.items;
    let dragCard = data.items[dragIndex];
    tmpItems.splice(dragIndex,1);
    console.log(dragCard);
  //   Array.prototype.Insert = function (item, before) {
  //     if (!item) return;
  //     if (before == null || before < 0 || before > this.length - 1) {
  //         this.push(item);
  //         return;
  //     }
  //     this.splice(before, 0,item );
  // }
  // tmpItems.Insert(dragCard,hoverIndex);
  tmpItems.splice(hoverIndex,0,dragCard);
  console.log(tmpItems);
  setData({...data,items:tmpItems,updateSort:true});


    // setData(data.items, {
    //     $splice: [
    //         [dragIndex, 1],
    //         [hoverIndex, 0, dragCard],
    //     ],
    // }));
}, [data.items]);


  const fetch = async (items) => {
    setData({
      items: data.items,
      isFetching: true,
      message: null,
    });
    let tmpItems = [];
    console.log(user);
    let getEventData = await firebase.functions().httpsCallable("app/getEventData");
    let result = await getEventData({userEmail: user.email, userRole:user.role });

    let resultData = result.data;
    console.log(resultData[0].createdAt);
    console.log('---')
      setData({
          items: resultData,
          isFetching: false,
          message: null,
        });
  

    let tmpFeaturedItems = [];
    let tmpArchivedItems = [];
  //   let queryRef = null;
  //   if(user.role === 1) {
  //     queryRef = db.collection("events")
  //     .orderBy("sortOrder");
  
  //     } else if (user.groups && user.groups.length > 0) {
  //       queryRef = db.collection("events")
  //       // .where("groupId","in",user.groups)
  //       .where()
  //       .orderBy("sortOrder");
  //     } else {
  // //       Sentry.captureMessage("User " + user.email + " is not admin and is not assigned to any groups");
  // return;
  //     }
  //     queryRef
  //     .get()
  //     .then(function (querySnapshot) {
  //       querySnapshot.forEach(function (doc) {
  //         let tmpItem = doc.data();
  //         tmpItem.id = doc.id;
  //         if(!tmpItem.galleryImages)
  //           tmpItem.galleryImages = [];
  //         if(!tmpItem.ctaList)
  //           tmpItem.ctaList = [];
  //           if(!tmpItem.videoList)
  //           tmpItem.videoList = [];
  //           if(!tmpItem.locationList)
  //           tmpItem.locationList = [];
  //           if(!tmpItem.vehicleList)
  //           tmpItem.vehicleList = [];
  //           if(!tmpItem.additionalFields)
  //           tmpItem.additionalFields = [];
  //         tmpItems.push(tmpItem);
  //       });
  //       console.log(moment(tmpItems[0].updatedAt.toDate()).format("MM/DD/YYYY h:mm A"));
  //       // setData({
  //       //   items: tmpItems,
  //       //   isFetching: false,
  //       //   message: null,
  //       // });
  //     })
  //     .catch(function (error) {
  //       console.log("Error getting documents: ", error);
  //       setData({
  //         items: data.items,
  //         isFetching: false,
  //         message: null,
  //       });
  //     });
  };
  useEffect(() => {
    fetch();console.log('fetching events');
    console.log(eventData);
    console.log('0000');
  }, []);
  useEffect(() => {
    if(data.updateSort) {
      data.items.map((item,i) => {
        if(item.listedEvent && item.status == "Active")
          item.sortOrder = i;
        else if(!item.listedEvent && item.status == "Active")
          item.sortOrder = i + 10000;
          else if(item.status == "Archived")
          item.sortOrder = i + 20000;
        db.collection("events").doc(item.id).set(item);
      });
      

      setData({...data,updateSort:false});
    }
    
  }, [data]);
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading items.</div>;
    } else {
      return null;
    }
  }

  const deleteItem = () => {};
  if (!user.isAuthenticated) {
    return <Redirect to="/manage/sign-in" />;
  }

  return (
    <div className="container">
      <h1>Events</h1>
      <div className="py-2"></div>
      <div className="row">
      <div className="col">
        <div className="form-group">
          <label>Search</label>
          <input type="text" name="searchText" value={searchText} onChange={(e) => {setSearchText(e.target.value)}} className="form-control" />
        </div>
        </div>
        {user.role < 3 && 
        <div className="text-right col">
        <NavLink to={"/manage/event"} onClick={() => { localStorage.setItem('eventData', JSON.stringify(newEvent));setEventData(newEvent)}} className="btn btn-theme">
          New Event
        </NavLink>
        </div>
}
      </div>
      <div className="py-3"></div>
      <Fetching />
      <h4 className="fs-xxl bold-font">Featured Events</h4>
      <div className="row">
        {data.items.map((g, i) => { 
          
          if ((!searchText || g.name.toLowerCase().indexOf(searchText.toLowerCase())  > -1) && g.status  == "Active" && g.listedEvent) { 
          return (
        <div key={g.id} className="col-md-4 col-lg-3 pb-4">
          <EventCard item={g} key={i} id={i} index={i} moveCard={moveCard} updateData={() => {fetch(); }} />
        </div>
          )
}
          
        })}
        </div>
    
        <div className="py-3"></div>
        <h4 className="fs-xxl bold-font">Other Events</h4>
        <div className="row">
        {data.items.map((g, i) => { 
          
          if ((!searchText || g.name.toLowerCase().indexOf(searchText.toLowerCase())  > -1) && g.status == "Active" && !g.listedEvent) { 
          return (   
            <div key={g.id} className="col-md-4 col-lg-3 pb-4">
            <EventCard item={g} key={i} id={i} index={i} moveCard={moveCard} updateData={() => {fetch(); }}/>
          </div>

)}})}
        </div>
        <div className="py-3"></div>
        <h4 className="fs-xxl bold-font">Archived Events</h4>
        <div className="row">
        {data.items.map((g, i) => { 
          
          if ((!searchText || g.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) && g.status == "Archived") { 
          return (   
            <div key={g.id} className="col-md-4 col-lg-3 pb-4">
            <EventCard item={g} key={i} id={i} index={i} moveCard={moveCard} updateData={() => {fetch(); }} />
          </div>
)}})}
      </div>
    </div>
  );
};

export default Events;
