import React, { useState, useEffect, useRef,useContext } from "react";
import { Redirect } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
// import firebase from "firebase/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon } from "../../../Constants";
import "../../../assets/css/groups.css";
// import moment from "moment";
// import { EventDataContext } from "../EventDataContext";
// import { toast } from "react-toastify";
import "../../../assets/css/event.css";
import JoditEditor from "jodit-react";


const Settings = () => {
  const { user } = useContext(firebaseAuth);
  // const reducer = (state, payload) => ({ ...state, ...payload });
  const [inputs, setInputs] = useState({
    mailboxes: [],
    waiverDefaultCopy: [],
    optInDefaultCopy: [],
    eventDataRetention:0,
    offlineDataRetention:0
  });
  const [oldSettings, setOldSettings] = useState({});
  const [optInIndex, setOptInIndex] = useState(null);
  const [waiverIndex, setWaiverIndex] = useState(null);
  
  const optInCopyEditor = useRef(null);
  const waiverCopyEditor = useRef(null);
  const config = {
        minHeight:400,
        maxWidth:800,
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }
  useEffect(() => {
    console.log(user);
    db.collection("settings")
      .doc("current")
      .get()
      .then((docRef) => {
        setInputs(docRef.data());
        let oldSettings = docRef.data();
        oldSettings.timestamp = new Date().getUTCDate();
        setOldSettings(oldSettings);
      });
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection("settings").doc("current").set(inputs);

    db.collection("settings").add(oldSettings);
  };
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleOptInCopy = async (content) => {
    let optInDefaultCopy = inputs.optInDefaultCopy;
    optInDefaultCopy[optInIndex.key].content = content;
    setInputs({ ...inputs, optInDefaultCopy: optInDefaultCopy });
  };
  const handleWaiverCopy = async (content) => {
    let waiverDefaultCopy = inputs.waiverDefaultCopy;
    waiverDefaultCopy[waiverIndex.key].content = content;
    setInputs({ ...inputs, waiverDefaultCopy: waiverDefaultCopy });
  };
  if (!user.isAuthenticated || user.role > 1) {
    if (user.isAuthenticated && user.role > 1)
      return <Redirect to="/manage/permissions" />;
    else return <Redirect to="/manage/sign-in" />;
  }

  return (
    <div className="container">
      <h1>Global Settings</h1>
      <div className="py-2"></div>
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="card mb-4">
            <div className="card-header">
              <h2>Data Retention</h2>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className={"form-group"}>
                    <label className="form-label">
                      Event Data Retention (days)
                    </label>
                    <input
                      type="number"
                      id="eventDataRetention"
                      name="eventDataRetention"
                      value={inputs.eventDataRetention}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label className="form-label">
                      Offline Data Retention (days)
                    </label>
                    <input
                      type="number"
                      id="offlineDataRetention"
                      name="offlineDataRetention"
                      value={inputs.offlineDataRetention}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="card mb-4">
            <div className="card-header">
              <h2>Mailboxes</h2>
            </div>
            <div className="card-body">
              <div className="text-right">
                <button
                  className={"btn btn-theme"}
                  onClick={() => {
                    setInputs({
                      ...inputs,
                      mailboxes: [...inputs.mailboxes, ""],
                    });
                  }}
                >
                  + Mailbox
                </button>
              </div>
              <div className="py-2"></div>
              {inputs?.mailboxes.map((mailbox, index) => (
                <div key={index} className="row">
                  <div className="col">
                    <input
                      onChange={(e) => {
                        let mailboxes = inputs.mailboxes;
                        mailboxes[index] = e.target.value;
                        setInputs({ ...inputs, mailboxes: mailboxes });
                      }}
                      type="text"
                      className="form-control"
                      value={mailbox}
                    />
                  </div>
                  <div className="col-auto">
                    <i className="ml-2">
                      <DeleteIcon />
                    </i>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header">
              <h2>Opt-In Language</h2>
            </div>
            <div className="card-body">
              <div className={optInIndex ? "d-none" : ""}>
                <div className="text-right">
                  <button
                    className={"btn btn-theme"}
                    onClick={() => {
                      setInputs({
                        ...inputs,
                        optInDefaultCopy: [...inputs.optInDefaultCopy, {}],
                      });
                    }}
                  >
                    + Opt-In
                  </button>
                </div>
                <div className="py-2"></div>
                {inputs?.optInDefaultCopy.map((optin, index) => (
                  <div key={index} className="row">
                    <div className="col">
                      <input
                        onChange={(e) => {
                          let optInDefaultCopy = inputs.optInDefaultCopy;
                          optInDefaultCopy[index] = {
                            name: e.target.value,
                            content: optInDefaultCopy[index].content,
                          };
                          setInputs({
                            ...inputs,
                            optInDefaultCopy: optInDefaultCopy,
                          });
                        }}
                        type="text"
                        className="form-control"
                        value={optin.name}
                      />
                    </div>
                    <div className="col-auto">
                      <i
                        className=""
                        onClick={() => {
                          console.log('----');
                          console.log({ key: index, content: optin.content });
                          setOptInIndex({ key: index, content: optin.content });
                        }}
                      >
                        <EditIcon          onClick={() => {
           
                          setOptInIndex({ key: index, content: optin.content });
                        }}/>
                      </i>
                      <i className="ml-2">
                        <DeleteIcon  />
                      </i>
                    </div>
                  </div>
                ))}
              </div>
              <div className={optInIndex ? "" : "d-none"}>
                <JoditEditor
            	ref={optInCopyEditor}
                value={optInIndex?.content}
                config={config}
		tabIndex={1} // tabIndex of textarea
		onBlur={newOptInContent => handleOptInCopy(newOptInContent)}
                    onChange={newOptInContent => {}}
            />
                <div className="text-right"><button onClick={() => {setOptInIndex(null)}} className={"btn btn-theme"}>Done</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header">
              <h2>Waivers</h2>
            </div>
            <div className="card-body">
              <div className={waiverIndex ? "d-none" : ""}>
                <div className="text-right">
                  <button
                    className={"btn btn-theme"}
                    onClick={() => {
                      setInputs({
                        ...inputs,
                        waiverDefaultCopy: [...inputs.waiverDefaultCopy, {}],
                      });
                    }}
                  >
                    + Waiver
                  </button>
                </div>
                <div className="py-2"></div>
                {inputs?.waiverDefaultCopy?.map((waiver, index) => (
                  <div key={index} className="row">
                    <div className="col">
                      <input
                        onChange={(e) => {
                          let waiverDefaultCopy = inputs.waiverDefaultCopy;
                          waiverDefaultCopy[index] = {
                            name: e.target.value,
                            content: waiverDefaultCopy[index].content,
                          };
                          setInputs({
                            ...inputs,
                            waiverDefaultCopy: waiverDefaultCopy,
                          });
                        }}
                        type="text"
                        className="form-control"
                        value={waiver.name}
                      />
                    </div>
                    <div className="col-auto">
                      <i
                        className=""
                        onClick={() => {
                          setWaiverIndex({ key: index, content: waiver.content });
                        }}
                      >
                        <EditIcon          onClick={() => {
           
                          setWaiverIndex({ key: index, content: waiver.content });
                        }}/>
                      </i>
                      <i className="ml-2">
                        <DeleteIcon  />
                      </i>
                    </div>
                  </div>
                ))}
              </div>
              <div className={waiverIndex ? "" : "d-none"}>
  
                        <JoditEditor
            	ref={waiverCopyEditor}
                value={waiverIndex?.content}
                config={config}
		tabIndex={1} // tabIndex of textarea
		onBlur={newWaiverContent => handleWaiverCopy(newWaiverContent)}
                    onChange={newWaiverContent => {}}
            />
                <div className="text-right"><button onClick={() => {setWaiverIndex(null)}} className={"btn btn-theme"}>Done</button></div>
              </div>
            </div>
                      </div>
        </div>
      </div>
      <div className="py-3"></div>
      <div className="row">
        <div className="col-auto">
          <button className="btn btn-theme" onClick={handleSubmit}>
            Save
          </button>
        </div>
        <div className="col-auto">
          <button className="btn btn-default">Cancel</button>
        </div>
      </div>
      <div className="py-3"></div>
    </div>
  );
};

export default Settings;
